import React from 'react'
import Link from '@material-ui/core/Link'
import PratnerBroadCastLogo from '../PartnersLogo/PratnerBroadCastLogo'
import PartnerSystemIntigrationLogo from '../PartnersLogo/PartnerSystemIntigrationLogo'
const Partners = () => {
  return (
   <>
   <section className='content'>
    <div className="container">
    <h1>Setron India</h1>
   <p>We, over the years, have carved a niche for ourselves in the Broadcast, Pro-Audio and Video market to provide complete end to end solutions. <br />
      We represent several leading international manufacturers in India such as
  </p>
  <span> <strong>ADC-COMMSCOPE, BW BROADCAST, CAD, CINEGY, CUBE-TEC, DAD, IKAN, JK-AUDIO, LYNX TECHNIK-AG, LYNX STUDIO, NTP TECHNOLOGY, PORTAPROMPT, ROLLS and  ZENON-MEDIA. </strong></span>
  <PratnerBroadCastLogo/>
  <h6 className='mt-5'>Click here to know more <Link href='http://www.setronindia.com/'>http://www.setronindia.com/</Link></h6>
    </div>
   </section>

   {/* <section className='content mt-5'>
    <div className="container">
    <h1>Tech Connect</h1>
   <p>Tech Connect is a leading Global Business Collaboration solution provider company headquartered in Delhi (Noida) with operations in 11 cities across India and having overseas presence in Hong Kong and Singapore. The company offers all kinds of Professional Audio visual equipment’s having specialization in Business Collaboration & Audio-Visual solutions. 
  </p>
  <span> <strong>Tech Connect delivers tailor made integrated communication solutions for Board - Rooms / Conference Rooms, Training facilities, Customer Experience Centers, Network Operating Centers (NOC). </strong></span>
  <PartnerSystemIntigrationLogo/>
  <h6 className='mt-5'>Click here to know more <Link href='https://www.linkedin.com/company/tech-connect-services-pvt--ltd-/?originalSubdomain=in '>https://www.linkedin.com/company/tech-connect-services-pvt--ltd-/?originalSubdomain=in </Link></h6>
    </div>
   </section> */}
   </>
  )
}

export default Partners