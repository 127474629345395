export const MenuData = [

    {
        name: "About",
        href: "/about",
        has_children: false,
    },
    {
        name: "Home",
        href: "/home",
        has_children: false,
    },
    {
        name: "Services",
        href: "/services",
        has_children: true,
        children: [
            {
                name: "Audio",
                href: "/av",
                has_children: false,
            },
            {
                name: "Video",
                href: "/video",
                has_children: false,
            },
            {
                name: "Lighting",
                href: "/lighting",
                has_children: false,
            },
            {
                name: "Broadcast",
                href: "/broadcast",
                has_children: false,
            },
            {
                name: "Acoustics",
                href: "/acoustics",
                has_children: false,
            },
        ]
    },
    {
        name: "Partners & References",
        href: "/partners",
        has_children: false,
    }
]