import React from 'react'
import {AiOutlineUsergroupAdd} from "react-icons/ai";
import {BiCodeAlt} from "react-icons/bi";
import {FaAward} from "react-icons/fa";
import CountUp from 'react-countup';
import '../../../assets/css/style.css'

const Index = () => {
    return (
        <>
            <section id="counter-area" className="py100 bg-color">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area">
                                <div className="counter-icon">
                                    <i><AiOutlineUsergroupAdd/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={1500}/></h2>
                                    <h4>My Client</h4>
                                    <p>We have served over 1500 satisfied clients worldwide, delivering top-notch solutions tailored to their needs.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area responsive-md">
                                <div className="counter-icon">
                                    <i><BiCodeAlt/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={1000}/></h2>
                                    <h4>Project Complete</h4>
                                    <p>Successfully completed over 350 projects across various industries, ranging from startups to enterprise solutions.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="counter-area responsive-sm">
                                <div className="counter-icon">
                                    <i><FaAward/></i>
                                </div>
                                <div className="counter-body">
                                    <h2 className="counter"><CountUp end={500}/></h2>
                                    <h4>Award</h4>
                                    <p>Recognized with over 500 industry awards for excellence in design, development, and innovation in the tech space.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index
