import React from 'react'
import img1 from '../../../assets/img/about/1.png'
import {Link} from 'react-router-dom'

const Index = () => {
    return (
        <>
            <section id="service-dec" className="py100">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="service-dec-img animation-img-two">
                                <img alt="" src={img1} />
                            </div>
                        </div>
                        <div className="col-lg-6 col-md-12 col-sm-12 col-12">
                            <div className="service-dec-right-side">
                                <div className="left-side-title">
                                    <h3 style={{textAlign:"justify"}}>Let's Build Something Great Together</h3>
                                    <h2 className="pt10" style={{textAlign:"justify"}}>
                                    Unlock Your Business's Full Potential with Our Expert Solutions
                                    </h2>
                                </div>
                                <div className="service-dec-left-text" style={{textAlign:"justify"}}>
                                    <p style={{textAlign:"justify"}}>We specialize in delivering innovative, scalable, and results-driven solutions
                                        tailored to your business needs. Whether you need cutting-edge technology, 
                                        custom development, or strategic consulting, our team is here to help you 
                                        succeed. Let's collaborate and take your business to new heights.
                                    </p> 
                                    {/* <Link to="/service_details" className="btn btn-theme">Get Started Project</Link> */}
                                    <Link to="/contact" className="btn btn-theme">Contact With Us</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Index
